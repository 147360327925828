<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ label }}
      </h4>

      <div class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="dataLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>
        <select
          v-if="!dataLoading"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          v-model="row.select_id"
          v-on:change="onSelectChange"
        >
          <option v-for="(row, idx) in data" :key="idx" :value="row.id">
            {{ row[$i18n.locale]["title"] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "CardSelect",
  props: ["label", "select_id", "check_id", "refs"],
  data() {
    return {
      auth: {
        role: "",
        access_token: "",
      },
      row: {
        select_id: this.$props.select_id,
        check_id: this.$props.check_id,
      },
      checkId: this.$props.check_id,
      data: [],
      dataLoading: true,
    };
  },
  mounted() {},
  watch: {
    check_id: function (newVal) {
      // watch it
      this.row.check_id = newVal;
      this.fetchData();
    },
  },
  created() {
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchData();
  },
  methods: {
    onSelectChange() {
      const data = {
        select_id: this.row.select_id,
      };
      this.$emit("selectChange", data);
    },

    fetchData() {
      this.dataLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs,
        method: "GET",
        data: {},
        params: {
          status: "active",
          is_root: this.auth.role == "root" ? true : false,
          paginate: 1000,
          lecturer_id: this.row.check_id,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.data = res.data.data.rows;
        })
        .catch((err) => {
          this.dataLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>
